import { configureChains, createClient, goerli } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";

import { publicProvider } from "@wagmi/core/providers/public";
import { mainnet, sepolia } from "wagmi/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import { env } from "~/env.mjs";

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, goerli, sepolia],
  [
    alchemyProvider({
      apiKey: env.NEXT_PUBLIC_INFURA_API_KEY,
    }),
    publicProvider(),
  ]
);

export const injected = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: true,
  },
});

export const walletConnect = new WalletConnectConnector({
  chains,
  options: {
    isNewChainsStale: false,
    projectId: env.NEXT_PUBLIC_WALLET_CONNECT_ID,
    showQrModal: true,
    qrModalOptions: {
      themeMode: "dark",
    },
  },
});

export const client = createClient({
  autoConnect: true,

  provider,
  webSocketProvider,
  connectors: [injected, walletConnect],
});
