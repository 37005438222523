import { WaxJS } from "@waxio/waxjs/dist";

import AnchorLink, {
  type LinkSession,
  type TransactArgs,
  type TransactResult as aTransactResult,
} from "anchor-link";
import { Api, JsonRpc } from "eosjs";
import { createContext, useContext, useEffect, useState } from "react";

import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import {
  type SignatureProvider,
  type TransactResult,
} from "eosjs/dist/eosjs-api-interfaces";
import {
  type PushTransactionArgs,
  type ReadOnlyTransactResult,
} from "eosjs/dist/eosjs-rpc-interfaces";
import { env } from "~/env.mjs";
import { type Transaction } from "~/type/Wax";

export enum SUPPORTED_WALLET {
  WAX = "WAX",
  ANCHOR = "ANCHOR",
}

interface WaxWalletContextType {
  wax?: WaxJS;
  api?: Api;
  rpc?: JsonRpc;
  anchorSession?: LinkSession;
  loginWithWax: () => Promise<void>;
  loginWithAnchor: () => Promise<void>;
  logout: () => void;
  signTransaction: (
    transaction: Transaction | TransactArgs,
    broadcast?: boolean
  ) => Promise<
    | TransactResult
    | ReadOnlyTransactResult
    | PushTransactionArgs
    | aTransactResult
  >;
  isConnected: boolean;
  walletAddress?: string;
  publicKey?: string | string[];
  connectionType?: SUPPORTED_WALLET;
  isConnecting: boolean;
  error?: unknown;
  testnet?: boolean;
}

const WaxWalletContext = createContext<WaxWalletContextType | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}

export const WaxWalletProvider: React.FC<Props> = ({ ...props }) => {
  const [wax, setWax] = useState<WaxJS>();
  const [api, setApi] = useState<Api>();
  const [rpc, setRpc] = useState<JsonRpc>();
  const [anchorSession, setAnchorSession] = useState<LinkSession>();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [isTestnet, setIsTestnet] = useState<boolean>(false);

  const isConnected = wax || anchorSession ? true : false;
  const walletAddress = wax
    ? wax.userAccount
    : anchorSession
    ? anchorSession.auth.actor.toString()
    : undefined;
  const connectionType = wax
    ? SUPPORTED_WALLET.WAX
    : anchorSession
    ? SUPPORTED_WALLET.ANCHOR
    : undefined;

  const publicKey = wax
    ? wax.pubKeys
    : anchorSession
    ? anchorSession.publicKey.toString()
    : undefined;

  // WAX Cloud Wallet login function
  const loginWithWax = async () => {
    setIsConnecting(true);
    try {
      const waxInstance = new WaxJS({
        rpcEndpoint: env.NEXT_PUBLIC_WAX_RPC_URL,
      });
      await waxInstance.login();
      setWax(waxInstance);
      setRpc(new JsonRpc(waxInstance.rpc.endpoint));
      setApi(
        new Api({
          rpc: waxInstance.rpc,
          signatureProvider: waxInstance.api.signatureProvider,
        })
      );
      setIsConnecting(false);
    } catch (error) {
      setError(error);
      setIsConnecting(false);
    }
  };

  // Anchor Wallet login function
  const loginWithAnchor = async () => {
    setIsConnecting(true);
    const transport = new AnchorLinkBrowserTransport();
    const link = new AnchorLink({
      transport,
      chains: [
        {
          nodeUrl: env.NEXT_PUBLIC_WAX_RPC_URL,
          chainId: env.NEXT_PUBLIC_WAX_CHAIN_ID,
        },
        {
          nodeUrl: env.NEXT_PUBLIC_WAXTEST_RPC_URL,
          chainId: env.NEXT_PUBLIC_WAXTEST_CHAIN_ID,
        },
      ],
    });
    try {
      const identity = await link.login("RFOX ID");
      const { session, chain } = identity;
      const chainId = chain.chainId.toString();
      const isTestnet = chainId === env.NEXT_PUBLIC_WAXTEST_CHAIN_ID;

      setAnchorSession(session);
      setRpc(
        new JsonRpc(
          isTestnet
            ? env.NEXT_PUBLIC_WAXTEST_RPC_URL
            : env.NEXT_PUBLIC_WAX_RPC_URL
        )
      );
      setApi(
        new Api({
          rpc: new JsonRpc(
            isTestnet
              ? env.NEXT_PUBLIC_WAXTEST_RPC_URL
              : env.NEXT_PUBLIC_WAX_RPC_URL
          ),
          signatureProvider:
            session.makeSignatureProvider() as SignatureProvider,
        })
      );
      setIsTestnet(isTestnet);
      setIsConnecting(false);
    } catch (error) {
      setIsConnecting(false);
      setError(error);
    }
  };

  // Logout function
  const logout = async () => {
    if (anchorSession) {
      await anchorSession.remove();
    }

    setWax(undefined);
    setApi(undefined);
    setRpc(undefined);
    setAnchorSession(undefined);
    setIsTestnet(false);
  };

  const tryAutoLoginWithWax = async (): Promise<boolean> => {
    try {
      const waxInstance = new WaxJS({
        rpcEndpoint: env.NEXT_PUBLIC_WAX_RPC_URL,
      });
      const autoLoggedIn = await waxInstance.isAutoLoginAvailable();

      if (autoLoggedIn) {
        await waxInstance.login();
        setWax(waxInstance);
        setRpc(new JsonRpc(waxInstance.rpc.endpoint));
        setApi(
          new Api({
            rpc: waxInstance.rpc,
            signatureProvider: waxInstance.api.signatureProvider,
          })
        );

        return true;
      }
    } catch (error) {
      console.error("Failed to auto-login with WAX Cloud Wallet:", error);
    }

    return false;
  };

  const tryAutoLoginWithAnchor = async (): Promise<boolean> => {
    try {
      const transport = new AnchorLinkBrowserTransport();
      const link = new AnchorLink({
        transport,
        chains: [
          {
            nodeUrl: env.NEXT_PUBLIC_WAX_RPC_URL,
            chainId: env.NEXT_PUBLIC_WAX_CHAIN_ID,
          },
          {
            nodeUrl: env.NEXT_PUBLIC_WAXTEST_RPC_URL,
            chainId: env.NEXT_PUBLIC_WAXTEST_CHAIN_ID,
          },
        ],
      });
      const session = await link.restoreSession("RFOX ID");

      if (session) {
        const chainId = session.chainId.toString();
        const isTestnet = chainId === env.NEXT_PUBLIC_WAXTEST_CHAIN_ID;

        setAnchorSession(session);
        setRpc(
          new JsonRpc(
            isTestnet
              ? env.NEXT_PUBLIC_WAXTEST_RPC_URL
              : env.NEXT_PUBLIC_WAX_RPC_URL
          )
        );
        setApi(
          new Api({
            rpc: new JsonRpc(
              isTestnet
                ? env.NEXT_PUBLIC_WAXTEST_RPC_URL
                : env.NEXT_PUBLIC_WAX_RPC_URL
            ),
            signatureProvider:
              session.makeSignatureProvider() as SignatureProvider,
          })
        );
        setIsTestnet(isTestnet);

        return true;
      }
    } catch (error) {
      console.error("Failed to auto-login with Anchor Wallet:", error);
    }

    return false;
  };

  useEffect(() => {
    const loadAccount = async () => {
      const loggedIn = await tryAutoLoginWithAnchor();
      if (!loggedIn) await tryAutoLoginWithWax();
    };

    if (isFirstLoad) {
      void loadAccount();
      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  const signTransaction = async (
    transaction: Transaction | TransactArgs,
    broadcast = true
  ) => {
    if (!api || !rpc) {
      throw new Error("No wallet connected");
    }

    if (wax) {
      try {
        const result = await wax.api.transact(transaction as Transaction, {
          blocksBehind: 3,
          expireSeconds: 30,
          broadcast,
        });
        return result;
      } catch (error) {
        console.error(
          "Failed to sign transaction with WAX Cloud Wallet:",
          error
        );
        throw error;
      }
    } else if (anchorSession) {
      try {
        const result = await anchorSession.transact(
          transaction as TransactArgs,
          {
            broadcast,
          }
        );
        return result;
      } catch (error) {
        console.error("Failed to sign transaction with Anchor Wallet:", error);
        throw error;
      }
    } else {
      throw new Error("No wallet connected");
    }
  };

  const value: WaxWalletContextType = {
    wax,
    api,
    rpc,
    anchorSession,
    loginWithWax,
    loginWithAnchor,
    logout,
    signTransaction,
    isConnected,
    walletAddress,
    publicKey,
    connectionType,
    isConnecting,
    error,
    testnet: isTestnet,
  };

  return (
    <WaxWalletContext.Provider value={value}>
      {props.children}
    </WaxWalletContext.Provider>
  );
};

export const useWaxWallet = () => {
  const context = useContext(WaxWalletContext);
  if (context === undefined) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};
