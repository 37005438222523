import { createContext, useState, type ReactNode } from "react";

interface Notification {
  message: string;
  type: "info" | "success" | "warning" | "error";
}

interface NotificationContextType {
  notification: Notification | null;
  showNotification: (
    message: string,
    type?: "info" | "success" | "warning" | "error"
  ) => void;
  hideNotification: () => void;
  isOpen: boolean;
}

export const NotificationContext = createContext<NotificationContextType>({
  notification: null,
  isOpen: false,
  showNotification: () => {
    //void
  },
  hideNotification: () => {
    //void
  },
});

interface Props {
  children: ReactNode;
}

export const NotificationProvider: React.FC<Props> = ({ ...props }) => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showNotification = (
    message: string,
    type: "info" | "success" | "warning" | "error" = "info"
  ) => {
    setNotification({ message, type });
    setIsOpen(true);
  };

  const hideNotification = () => {
    setNotification(null);
    setIsOpen(false);
  };

  setTimeout(() => {
    if (isOpen) {
      hideNotification();
    }
  }, 5000);

  return (
    <NotificationContext.Provider
      value={{ notification, showNotification, hideNotification, isOpen }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
