import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NODE_ENV: z.enum(["development", "test", "production"]),
    SECRET_COOKIE_PASSWORD: z.string().min(32),
    PORTAL_API_URL: z.string().url(),
    AUTH_REDIRECT_URL: z.string().url(),
    GOOGLE_RECAPTCHA_SITE_SECRET: z.string(),
    EXPERIMENTAL: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
    NEXT_PUBLIC_PORTAL_API_URL: z.string().url(),
    NEXT_PUBLIC_AUTH_REDIRECT_URL: z.string().url(),
    NEXT_PUBLIC_WALLET_CONNECT_ID: z.string(),
    NEXT_PUBLIC_INFURA_API_KEY: z.string(),
    NEXT_PUBLIC_GTM_ID: z.string(),
    NEXT_PUBLIC_APP_AUTH_REDIRECT_URL: z.string().url(),
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY: z.string(),
    NEXT_PUBLIC_READY_PLAYER_ME_SUBDOMAIN: z.string(),
    NEXT_PUBLIC_WEBSITE_URL: z.string().url(),
    NEXT_PUBLIC_WAX_RPC_URL: z.string().url(),
    NEXT_PUBLIC_WAX_CHAIN_ID: z.string(),
    NEXT_PUBLIC_WAXTEST_RPC_URL: z.string().url(),
    NEXT_PUBLIC_WAXTEST_CHAIN_ID: z.string(),
    NEXT_PUBLIC_APP_PROTOCOL: z.string(),
    NEXT_PUBLIC_IPFS_GATEWAY: z.string().url(),
    NEXT_PUBLIC_IPFS_CLOUDINARY_MAPPING: z.string(),
    NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: z.string(),
    NEXT_PUBLIC_EXPERIMENTAL: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
    NEXT_PUBLIC_AVATURN_SUBDOMAIN: z.string(),
    NEXT_PUBLIC_AVATURN: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
    NEXT_PUBLIC_NPC_ENABLE_GREETING: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
    NEXT_PUBLIC_NPC_ENABLE_ACTIONS: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
    NEXT_PUBLIC_FACEBOOK_LOGIN: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
    NEXT_PUBLIC_EMAIL_LOGIN: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
    NEXT_PUBLIC_ACCESS_RFOXID: z
      .string()
      .transform((s) => s === "true" || s === "t" || s === "1"),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    SECRET_COOKIE_PASSWORD: process.env.SECRET_COOKIE_PASSWORD,
    PORTAL_API_URL: process.env.NEXT_PUBLIC_PORTAL_API_URL,
    AUTH_REDIRECT_URL: process.env.NEXT_PUBLIC_AUTH_REDIRECT_URL,
    EXPERIMENTAL: process.env.NEXT_PUBLIC_EXPERIMENTAL,
    NEXT_PUBLIC_PORTAL_API_URL: process.env.NEXT_PUBLIC_PORTAL_API_URL,
    NEXT_PUBLIC_AUTH_REDIRECT_URL: process.env.NEXT_PUBLIC_AUTH_REDIRECT_URL,
    NEXT_PUBLIC_WALLET_CONNECT_ID: process.env.NEXT_PUBLIC_WALLET_CONNECT_ID,
    NEXT_PUBLIC_INFURA_API_KEY: process.env.NEXT_PUBLIC_INFURA_API_KEY,
    NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    NEXT_PUBLIC_APP_AUTH_REDIRECT_URL:
      process.env.NEXT_PUBLIC_APP_AUTH_REDIRECT_URL,
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY,
    GOOGLE_RECAPTCHA_SITE_SECRET: process.env.GOOGLE_RECAPTCHA_SITE_SECRET,
    NEXT_PUBLIC_READY_PLAYER_ME_SUBDOMAIN:
      process.env.NEXT_PUBLIC_READY_PLAYER_ME_SUBDOMAIN,
    NEXT_PUBLIC_WEBSITE_URL: process.env.NEXT_PUBLIC_WEBSITE_URL,
    NEXT_PUBLIC_WAX_RPC_URL: process.env.NEXT_PUBLIC_WAX_RPC_URL,
    NEXT_PUBLIC_WAX_CHAIN_ID: process.env.NEXT_PUBLIC_WAX_CHAIN_ID,
    NEXT_PUBLIC_WAXTEST_RPC_URL: process.env.NEXT_PUBLIC_WAXTEST_RPC_URL,
    NEXT_PUBLIC_WAXTEST_CHAIN_ID: process.env.NEXT_PUBLIC_WAXTEST_CHAIN_ID,
    NEXT_PUBLIC_APP_PROTOCOL: process.env.NEXT_PUBLIC_APP_PROTOCOL,
    NEXT_PUBLIC_IPFS_GATEWAY: process.env.NEXT_PUBLIC_IPFS_GATEWAY,
    NEXT_PUBLIC_IPFS_CLOUDINARY_MAPPING:
      process.env.NEXT_PUBLIC_IPFS_CLOUDINARY_MAPPING,
    NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME:
      process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    NEXT_PUBLIC_EXPERIMENTAL: process.env.NEXT_PUBLIC_EXPERIMENTAL,
    NEXT_PUBLIC_AVATURN_SUBDOMAIN: process.env.NEXT_PUBLIC_AVATURN_SUBDOMAIN,
    NEXT_PUBLIC_AVATURN: process.env.NEXT_PUBLIC_AVATURN,
    NEXT_PUBLIC_NPC_ENABLE_GREETING:
      process.env.NEXT_PUBLIC_NPC_ENABLE_GREETING,
    NEXT_PUBLIC_NPC_ENABLE_ACTIONS: process.env.NEXT_PUBLIC_NPC_ENABLE_ACTIONS,
    NEXT_PUBLIC_FACEBOOK_LOGIN: process.env.NEXT_PUBLIC_FACEBOOK_LOGIN,
    NEXT_PUBLIC_EMAIL_LOGIN: process.env.NEXT_PUBLIC_EMAIL_LOGIN,
    NEXT_PUBLIC_ACCESS_RFOXID: process.env.NEXT_PUBLIC_ACCESS_RFOXID,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
