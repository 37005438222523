import { ReCaptchaProvider } from "next-recaptcha-v3";

import { SWRConfig } from "swr";
import fetchJson from "~/lib/fetchJson";

import { Roboto, Roboto_Condensed } from "next/font/google";
import React, { type ReactElement, type ReactNode } from "react";
import { WagmiConfig } from "wagmi";
import { client } from "~/lib/chain";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { Analytics } from "@vercel/analytics/react";
import { NotificationProvider } from "~/context/NotificationContext";

import { type NextPage } from "next";
import { DefaultSeo } from "next-seo";
import { type AppProps, type NextWebVitalsMetric } from "next/app";
import Script from "next/script";
import { WaxWalletProvider } from "~/context/WaxWalletContext";
import { env } from "~/env.mjs";
import "~/styles/globals.css";

import { Provider as JotaiProvider } from "jotai";
// import { DevTools } from "jotai-devtools";

import { HighlightInit } from "@highlight-run/next/highlight-init";

const robotoCondensed = Roboto_Condensed({
  subsets: ["latin"],
  weight: ["300", "400", "700"],
  variable: "--font-roboto-condensed",
  display: "swap",
});

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["300", "400", "700"],
  variable: "--font-roboto",
  display: "swap",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  //Current workaround for font loading issue in Modal or Dialog
  React.useEffect(() => {
    if (typeof window) {
      const typefaceClasses = [
        roboto.variable,
        robotoCondensed.variable,
        "font-sans",
      ];
      const body = document.querySelector("body");
      body ? body.classList.add(...typefaceClasses) : null;
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  const wrapLayout = () => {
    return getLayout(<Component {...pageProps} />);
  };

  return (
    <>
      <HighlightInit
        projectId={"0dqp7qgo"}
        tracingOrigins
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: [],
        }}
      />

      <DefaultSeo
        title="RFOX VALT | Portal"
        description="A fully immersive shopping, retail, and entertainment experience in virtual reality. Combining various gaming elements and forming its own standalone metaverse."
        defaultTitle="RFOX VALT| Portal"
        openGraph={{
          type: "website",
          locale: "en_US",
          url: `https://${env.NEXT_PUBLIC_WEBSITE_URL}`,
          siteName: "RFOX VALT",
          images: [
            {
              url: "https://res.cloudinary.com/dpcll0lhr/image/upload/f_auto,w_1200,h_630/default/cover_yvrrqs.jpg",
              width: 1200,
              height: 630,
              alt: "RFOX Valt",
            },
            {
              url: "https://res.cloudinary.com/dpcll0lhr/image/upload/f_auto,w_1200,h_675/default/cover_yvrrqs.jpg",
              width: 1200,
              height: 675,
              alt: "RFOX Valt",
            },
            {
              url: "https://res.cloudinary.com/dpcll0lhr/image/upload/f_auto,w_800,h_418/default/cover_yvrrqs.jpg",
              width: 800,
              height: 418,
              alt: "RFOX Valt",
            },
          ],
        }}
        twitter={{
          handle: "@RFOX_VALT",
          site: "@RFOX_Official",
          cardType: "summary_large_image",
        }}
      />
      <main
        className={`${robotoCondensed.variable} ${roboto.variable} font-sans`}
      >
        <SWRConfig
          value={{
            fetcher: fetchJson,
            onError: (err) => {
              console.error(err);
            },
          }}
        >
          <WagmiConfig client={client}>
            <JotaiProvider>
              <QueryClientProvider client={queryClient}>
                <WaxWalletProvider>
                  <NotificationProvider>
                    <ReCaptchaProvider
                      strategy="lazyOnload"
                      reCaptchaKey={env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
                    >
                      {wrapLayout()}
                    </ReCaptchaProvider>
                  </NotificationProvider>
                </WaxWalletProvider>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
                {/* <DevTools /> */}
              </QueryClientProvider>
            </JotaiProvider>
          </WagmiConfig>
        </SWRConfig>
      </main>

      <Script
        id="google-tag-manager"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${env.NEXT_PUBLIC_GTM_ID}');`,
        }}
      />

      <Analytics />
    </>
  );
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === "web-vital") {
    console.log(metric);
    // The metric object ({ id, name, startTime, value, label }) is logged to the console
  }
}

export default MyApp;
